import { combineReducers } from "redux";
import models from "./mobilePhoneModelReducer";
import loader from "./loader.reducer";
import alertMessage from "./alert.reducers";
import authentication from "./authentication.reducers";

const rootReducer = combineReducers({
  models,
  loader,
  alertMessage,
  authentication
});

export default rootReducer;
