import {userCosntants} from '../constants/user.constants';

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? {loggedIn: true,user} : {}

export default function authentication(state=initialState,action)
{
    switch(action.type)
    {
        case userCosntants.LOGIN_REQUEST:
            return {
                loggedIn: true,
                user : action.user
            }
        case userCosntants.LOGIN_SUCCESS:
            {
                return {loggedIn: true,
                user : action.data
                }            
            }
        case userCosntants.LOGIN_FAILURE:
            {
                return {
                }
            }
        case  userCosntants.LOGOUT:
            {
                return {}
            }
        default: 
            return state;
    }
}