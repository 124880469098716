import {alertCosntants} from '../constants/alert.constants';

export default function alertMessage(state= {}, action={} )
{
    switch(action.type)
    {
        case alertCosntants.SUCCESS:
        {
            return {
                ...state,
                type: "success",
                message : action.message.message,
                description : action.message.description
            }
        }
        case alertCosntants.CLEAR:
            return {}
        case alertCosntants.ERROR:
            return {
                ...state,
                type: "error",
                message : action.message.message,
                description : action.message.description
            }
        default:
            return state;
    }
}