import React from 'react';
import PropTypes from 'prop-types';
import { Layout, PageHeader, Spin } from 'antd/lib';

import './PageLayout.less';
import Spinner from '../controls/Spinner/Spinner';
import AlertDisplay from '../controls/AlertDisplay/AlertDisplay';

const { Content } = Layout;

const PageLayout = ({ title, children,props }) => {
  return (
    <>
      <Content className="page-content">
        <AlertDisplay/>
        <Spinner>
          {children}
        </Spinner>
      </Content>
      
    </>
  );
};

// PageLayout.propTypes = {
//   title: PropTypes.string.isRequired,
//   children: PropTypes.element.isRequired,
// };

export default PageLayout;