import {loaderConstants} from '../constants/loader.constants'

export default function loader(state = {loading:false},action)
{
    switch(action.type)
    {
        case loaderConstants.START:
            return{
                loading: true
            };
        case loaderConstants.STOP:
            return{
                loading: false
            };
        default:
            return state
    }
}