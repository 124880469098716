// src/redux/store.js

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

// Configure logger middleware
const loggerMiddleware = createLogger();

// Define the store configuration
const store = configureStore({
  reducer: rootReducer, // The root reducer object
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Middleware configuration
      immutableCheck: {
        // Configure immutable state invariant
        warnAfter: 64, // Warn after a specific number of keys
      },
    }).concat(thunkMiddleware, loggerMiddleware, reduxImmutableStateInvariant()),
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
  // Optionally, set preloadedState here
  // preloadedState: initialState,
});

export default store;
