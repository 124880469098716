// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-content {
  padding: 15px;
  background: #fff;
}
.site-page-header {
  background: #fff;
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/layout/PageLayout.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;AACF;AAEA;EACE,gBAAA;EACA,aAAA;AAAF","sourcesContent":[".page-content {\n  padding: 15px;\n  background: #fff;\n}\n\n.site-page-header {\n  background: #fff;\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
