import { Alert } from "antd/lib";
import React from "react";
import { useSelector } from "react-redux";

const AlertDisplay = () => {

    const alertDetails = useSelector(state => state.alertMessage);

    return (
        <>
           {alertDetails.description && 
           <Alert type="error" message={alertDetails.description} banner />

        //    <Alert
        //         description={alertDetails.description}
        //         type={alertDetails.type}
        //         showIcon
        //         closable
        //     />
           }
        </>
    )
}

export default AlertDisplay