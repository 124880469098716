import React from 'react';
// import 'antd/dist/antd.css';
//import 'antd/dist/antd.css';
import './Spinner.css';

import { Spin } from 'antd/lib';
import { useSelector } from 'react-redux';

const Spinner = ({children})=>{

  const loader = useSelector(state => state.loader);

  return(
      <Spin spinning={loader.loading}>
        {children}
      </Spin>
  )  
};

export default Spinner

