import React from "react";

import { Layout, Menu } from "antd/lib";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import AlertDisplay from "../controls/AlertDisplay/AlertDisplay";
import Spinner from "../controls/Spinner/Spinner";

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

export default class MainLayout extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    const { children } = this.props;
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider breakpoint="lg" collapsedWidth="0">
          <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
            {/* {
              menuData.map((data,index)=>{
                return (<SubMenu key={index} icon={<UserOutlined />} title={data.name}>
                    {
                      data.menuItems.map((menuItem,index)=>{
                        return( <Menu.Item key={"menuItem"+index} url={menuItem.url}> <Link key={"link"+index} to={menuItem.url}/> {menuItem.name} </Menu.Item>)
                      })
                    }
                </SubMenu>)
              })
            } */}
            <SubMenu key="1" icon={<UserOutlined />} title="Admin">
              <Menu.Item key="1">
                <Link to="/table/c03b8993-c4df-4349-ab75-66fa6d17c6ab/list/3b8ff057-ffc8-460d-b87f-c3bd9d6b8c77" />{" "}
                Tables{" "}
              </Menu.Item>
              <Menu.Item key="2">
                <Link
                  to="/table/32630e4e-881e-496c-8b0f-e231db49b3e7/list/d56ae604-71f4-4e7f-9fdb-0fa5e1c4ee0d"
                  title="Table Relationship"
                />{" "}
                Tables Relations{" "}
              </Menu.Item>
              <Menu.Item key="3">
                <Link
                  to="/table/b0ae819b-1696-4938-8808-9e6663905094/list/b089fa34-db55-49e4-872b-f991b5b1c756"
                  title="Table"
                />{" "}
                Controls{" "}
              </Menu.Item>
              <Menu.Item key="4">
                <Link
                  to="/table/8216d804-42f9-464d-93c1-4d129c5eace1/list/3135217c-dce8-4e7c-a242-a4282de02f93"
                  title="Columns"
                />{" "}
                Table Columns{" "}
              </Menu.Item>
              <Menu.Item key="5">
                <Link
                  to="/table/75f46caf-f3ad-4f3e-be68-3ceffed2f584/list/a5e5e016-e1d0-4eb1-85ed-7355acbb2e6d"
                  title="Views"
                />{" "}
                Views{" "}
              </Menu.Item>
              <Menu.Item key="6">
                <Link
                  to="/table/974f1f85-ed2c-405e-8613-4e2c54bbcb8c/list/e05447c7-9524-4c4c-84ef-c2bd4cff2665"
                  title="Table Column Mapping for Data Transfer"
                />{" "}
                Table Column Mapping for Data Transfer{" "}
              </Menu.Item>
              <Menu.Item key="7">
                <Link
                  to="/table/525a1aa8-a40b-4577-bd5e-6117db69559e/list/14663ba9-ac92-42c5-8cd6-f4272b4a3bd9"
                  title="Product Generator for Marketplace"
                />{" "}
                Generate Products{" "}
              </Menu.Item>
              <Menu.Item key="8">
                <Link
                  to="/table/c03b8993-c4df-4349-ab75-66fa6d17c6ab/edit/8cbfc1ee-0c8f-40fb-9d4f-c4f60380cf1e"
                  title="Template"
                />{" "}
                Template{" "}
              </Menu.Item>
            </SubMenu>
            <SubMenu key="100" icon={<UserOutlined />} title="Catch Experts">
              <Menu.Item key="101">
                <Link 
                  to="/table/ae9d80c8-f2dc-4096-80cd-71281dd1e3f6/list/9d21034a-40d2-4f6f-95e8-525f9f910221" />{" "}
                Page SEO
              </Menu.Item>
              <Menu.Item key="102">
                <Link
                  to="/table/a30a60be-7a31-4d74-afcb-da0149ff202e/list/c4b0865d-082b-4123-8107-e3e8fd5d7b1a"
                  title="Page Details"
                />
                Page Details
              </Menu.Item>
              <Menu.Item key="103">
                <Link
                  to="/table/85d5ffd0-33c4-4fa6-87c7-bcd7bbf5a2d8/list/5aab9b64-ca8f-4cc6-b299-52becfd0b404"
                  title="FAQ Summary"
                />
                FAQ Summary
              </Menu.Item>
              <Menu.Item key="104">
                <Link
                  to="/table/27242c8e-8679-4acc-95f8-d600ca6113e0/list/ed723400-5848-4a51-b988-fb3861805a01"
                  title="Columns"
                />{" "}
                FAQ Q&A
              </Menu.Item>
              
            </SubMenu>
            {/* <SubMenu key="200" icon={<UserOutlined />} title="Images">
              <Menu.Item key="210">
                <Link
                  to="/table/0fd90c55-fbed-4271-b7f9-6f1a8a56c84e/list/26edef44-2976-4273-89d1-ee27ffc556db"
                  title="Images"
                />{" "}
                Images{" "}
              </Menu.Item>
              <Menu.Item key="211">
                <Link
                  to="/table/e53a77bf-e03e-46e6-8b7d-d77cd6499d45/list/7407aafc-f353-45fa-9e45-17e564f7f46d"
                  title="Groups"
                />{" "}
                Image Groups{" "}
              </Menu.Item>
            </SubMenu> */}
            {/* <SubMenu key="2" icon={<UserOutlined />} title="Marketplace">
              <Menu.Item key="2.1">
                <Link
                  to="/table/c03b8993-c4df-4349-ab75-66fa6d17c6ab/edit/475c3e9c-ddae-432c-b409-e6c545cae400"
                  title="Marketplace Products"
                />{" "}
                Products{" "}
              </Menu.Item>
            </SubMenu> */}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <AlertDisplay />
          <Spinner>
            <Content style={{ margin: "5px" }}>{children}</Content>
          </Spinner>
          <Footer style={{ textAlign: "center" }}>Fusion @2021</Footer>
        </Layout>
      </Layout>
    );
  }
}

// import React from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

// import { Layout, Menu, Breadcrumb } from 'antd/lib';
// import {
//   DesktopOutlined,
//   PieChartOutlined,
//   FileOutlined,
//   TeamOutlined,
//   UserOutlined,
// } from '@ant-design/icons';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

// import './MainLayout.less';

// const { SubMenu } = Menu;
// const { Header, Content, Sider } = Layout;

// const rootRoutes = ['/', '/about', '/contact'];
// const aboutSubRoutes = ['/about/me', '/about/company'];

// export default class MainLayout extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       collapsed: false,
//     };
//   }

//   onCollapse = (collapsed) => {
//     this.setState({ collapsed });
//   };

//   render() {
//     const { collapsed } = this.state;
//     const { children } = this.props;
//     return (
// <Layout>
//     <Header className="header">
//       <div className="logo" />
//       <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
//         <Menu.Item key="1">nav 1</Menu.Item>
//         <Menu.Item key="2">nav 2</Menu.Item>
//         <Menu.Item key="3">nav 3</Menu.Item>
//       </Menu>
//     </Header>
//     <Layout>
//       <Sider breakpoint="lg" collapsible collapsed={collapsed} onCollapse={this.onCollapse} width={200} >
//         <Menu
//           mode="inline"
//           defaultSelectedKeys={[
//             aboutSubRoutes.includes(window.location.pathname)
//               ? '1'
//               : rootRoutes.indexOf(window.location.pathname).toString(),
//           ]}          defaultOpenKeys={['sub1']}
//           style={{ height: '100%', borderRight: 0 }}
//         >
//           <SubMenu key="sub1" icon={<MenuUnfoldOutlined />} title="Pages">
//             <Menu.Item key="0" style={{ margin: '4px 0px 0px 2px', minHeight: 10 }}>
//               <Link to="/">
//                 <RightOutlined />
//                 <span className="menu-item-link">Home</span>
//               </Link>
//             </Menu.Item>
//             <Menu.Item key="1" style={{ margin: '4px 0px 0px 2px', minHeight: 10 }}>
//               <Link to="/about">
//               <RightOutlined />
//                 <span className="menu-item-link">About</span>
//               </Link>
//             </Menu.Item>
//             <Menu.Item key="2" style={{ margin: '4px 0px 0px 2px', minHeight: 10 }}>
//               <Link to="/contact">
//               <RightOutlined />
//                 <span className="menu-item-link">Contact</span>
//               </Link>
//             </Menu.Item>

//           </SubMenu>
//         </Menu>
//       </Sider>
//       <Layout style={{ padding: '0 1px 1px' }}>
//       </Layout>
//     </Layout>
//   </Layout>
//       );
//   }
// }

// MainLayout.propTypes = {
//   children: PropTypes.element.isRequired,
// };
