import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from "./layout/MainLayout";
import RouteWithSubRoutes from "./RouteWithSubRoutes";

import PageLayout from "./layout/PageLayout";

const Home = lazy(() => import("pages/home/Home"));
const About = lazy(() => import("pages/about/About"));
const Contact = lazy(() => import("pages/contact/Contact"));
const Login = lazy(() => import("pages/login/loginController"));
const List = lazy(() => import("pages/list/listGrid"));
const FNList = lazy(() => import("pages/fnlistsample/fnListSample"));
const FNTableList = lazy(() => import("pages/fntable/list/fnTableList"));
const FNTableFilter = lazy(() => import("pages/fntable/filter/FNTableFilter"));
const FNTableResize = lazy(() => import("pages/fntable/list/fnTableResize"));
const FNTableEdit = lazy(() => import("pages/fntable/edit/fnTableEdit"));
const FNTableInsertRow = lazy(() => import("pages/fntable/new/fnTableNew"));
const FNViewInsert = lazy(() => import("pages/fnview/new/fnViewNew"));

const PrivateRoutes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/list",
    component: FNList,
  },
  {
    path: "/table/:tableId/list/:viewId",
    component: FNTableList,
  },
  {
    path: "/table/:tableId/:viewId/filter",
    component: FNTableFilter,
  },
  {
    path: "/table/resizetable",
    component: FNTableResize,
  },
  {
    path: "/table/:tableId/edit/:rowId",
    component: FNTableEdit,
  },
  {
    path: "/table/:tableId/insert",
    component: FNTableInsertRow,
  },
  {
    path: "/view_columns/:viewId/edit",
    component: FNViewInsert,
  },
];

const PublicRoutes = [
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/login",
    component: Login,
  },
];

const PublicLayoutRoutes = {
  layout: PageLayout,
  paths: PublicRoutes,
};

const PrivateLayoutRoutes = {
  layout: MainLayout,
  paths: PrivateRoutes,
};

// const SetPrivateRoute = (layoutPaths) => {
//   const Layout = layoutPaths.layout;
//   const paths = layoutPaths.paths.map((x) => x.path);
//   console.log(paths);
//   const LayoutComponent = (
//     <Layout
//       children={layoutPaths.paths.map((x) => (
//         <Route key={x.path} {...x} />
//       ))}
//     />
//   );
//   return <Route exact path={paths} children={LayoutComponent} />;
// };

const SetPublicRoute = (layoutPaths) => {
  const Layout = layoutPaths.layout;
  const paths = layoutPaths.paths.map((x) => x.path);
  const LayoutComponent = (
    <Layout
      children={layoutPaths.paths.map((x) => (
        <Route key={x.path} {...x} />
      ))}
    />
  );
  return <Route exact path={paths} children={LayoutComponent} />;
};


const AppRouterV1 = () => {
  return (
    <Router>
      <Routes>

        {PublicRoutes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={
              <React.Suspense fallback={<div>Loading...</div>}>
                <PageLayout>
                  <route.component />
                </PageLayout>
              </React.Suspense>
            }
          />
        ))}
        {PrivateRoutes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={
              <React.Suspense fallback={<div>Loading...</div>}>
                <MainLayout>
                  <route.component />
                </MainLayout>
              </React.Suspense>
            }
          />
        ))}
      </Routes>
    </Router>
  );
};


// const AppRouterV1 = () => {
//   return (
//     <Router>
//     <BrowserRouter>
//       <Suspense fallback={<div className="lazy-loading">Loading...</div>}>
//         <SetPrivateRoute
//           layout={PrivateLayoutRoutes.layout}
//           paths={PrivateLayoutRoutes.paths}
//         />
//       </Suspense>

//       <Suspense fallback={<div className="lazy-loading">Loading...</div>}>
//         <SetPublicRoute
//           layout={PublicLayoutRoutes.layout}
//           paths={PublicLayoutRoutes.paths}
//         />
//       </Suspense>
//     </BrowserRouter>
//     </Router>
//   );
// };

export default AppRouterV1;
